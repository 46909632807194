const svgDir = require.context('!@svgr/webpack!/public/images/icon-component', true, /\.svg$/)

/**
 * This is a JavaScript function that renders an SVG icon based on the provided category and name.
 * @returns an SVG icon component.
 */

export default function Icon({ icon, className }) {
    if (!icon) return
    try {
        const Icon = svgDir(`./${icon}.svg`).default

        if (!Icon) return console.error(`Icon ${icon} not found`)

        return <Icon className={className} />
    } catch (e) {
        console.error(e)
    }
}
